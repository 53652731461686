<script lang="ts" setup>
const { t } = useI18n();
const props = defineProps<{
  hasBreakdowns?: boolean;
}>();
const appConfig = useAppConfig();
const localePath = useLocalePath();

const { isPageEnabled: isFeaturePageEnabled } = useFeatureFlags();

const links = computed(() => [
  { label: t("navigation.your_info"), to: localePath("/account/general/") },
  { label: t("navigation.subscriptions"), to: localePath("/account/subscriptions/") },
  { label: t("navigation.orders"), to: localePath("/account/orders/") },
  { label: t("navigation.breakdowns"), to: localePath("/account/breakdowns/"), hidden: !props.hasBreakdowns },
  { label: t("navigation.billing_information"), to: localePath("/account/billing/"), hidden: appConfig.useMVP },
  { label: "Feature Flag", to: localePath("/account/feature-flags/"), hidden: !isFeaturePageEnabled },
]);
</script>

<template>
  <div class="flex flex-col gap-6 overflow-hidden pxl-block">
    <template
      v-for="link in links"
      :key="link.label"
    >
      <NuxtLink
        v-if="!link.hidden"
        :to="link.to"
        class="account_menu_link"
      >
        {{ link.label }}
      </NuxtLink>
    </template>
  </div>
</template>

<style lang="scss">
.account_menu_link {
  @apply relative text-sm font-medium flex-shrink-0;

  &::before {
    @apply absolute -top-[6px] ltr:-left-[26px] rtl:-right-[26px] w-1.5 rounded-full transition-colors h-[150%];

    content: "";
  }

  &:hover::before {
    @apply opacity-75;
    background-color: #009bc9;
  }

  &.router-link-active {
    color: #009bc9;

    &::before {
      background-color: #009bc9;
    }
  }
}
</style>
